$text: lighten($grey, 20);

.social {
  @include display(flex);
  @include flex-direction(row);
  @include justify-content(flex-end);
  @include align-items(center);

  height: map-get($navHeight, desktop);

  &__item {
    margin-left: 16px;

    &__link {
      display: block;

      width: 16px;
      height: 16px;

      color: transparent;

      font-size: 0;
      
      overflow: hidden;

      svg {
        @include transition(opacity .2s, fill .2s);
      } 
      .site--dark & {
          svg {
              opacity: 1;
              fill: $text;
          }
          &:hover svg {
              fill: $white;
          }             
      }      
    }
  }
}