$text: lighten($grey, 33);

footer {
  html[data-type='post'] & {
      display: none;
      &.visible {
        display: block;
      } 
  }
}

.footer {
  // @include display(flex);
  // @include flex-direction(column);
  // @include justify-content(center);
  // @include align-items(center);

  background-color: $black;
  overflow: hidden;

  //height: 440px;

  .site--dark & {
    border-top: 1px solid $grey;
  }

  .nav-items  {
    padding-top: 18px;
  }

  &__copyright {
    position: absolute;

    bottom: 0;

    width: 100%;

    padding: 20px;

    text-align: center;

    color: $text;
  }

  a:hover {
    color: $white;
    opacity: 1;
  }

}

@include media(map-get($breakpoints, mobile)) {
  .footer {
    height: 340px;
    .nav-items {
      display: none;
    }    
  }
}