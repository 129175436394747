.search {
	@include display(flex);
	@include flex-direction(row-reverse);

	position: absolute;

    overflow: hidden;

	right: 0;

    @include transition(width .2s);

    &__icon,
    &__input {
    	@include bodyFont();
		
		font-size: $bodySize;
		line-height: $bodySizeLH;
	}

    &__icon {
    	@include order(1);

        cursor: pointer;
		
		color: $black;

		background-color: transparent;
		border: 0;
        svg {
            width: 16px;
            height: 19px;
            margin: 0 auto;
            fill: $grey;

            @include transition(opacity .2s, fill .2s);
        }
        &:hover svg {
            opacity: 0.6;
        }          

    }

    .site--dark & {
        &__icon {
            svg {
                opacity: 1;
                fill: $text;
            }
            &:hover svg {
                fill: $white;
            }
        } 
    }    

    &__input {
    	@include order(2);

    	min-width: 100px;
        height: 100%;        

		color: $black;

		background-color: $white;
		border: 0;
        .site--dark & {
            color: $white;

            background-color: $black;
        }          
    }
}

@media screen and (min-width: 481px) {

    .search {

        height: map-get($navHeight, tablet);
        width: map-get($navHeight, tablet) - 1px;

        border-left: 1px solid $bordergrey;

        &__icon {
            height: map-get($navHeight, tablet) - 1px;
            width: map-get($navHeight, tablet) - 1px;
            min-width: map-get($navHeight, tablet) - 1px;            
        }

        &--open {
            width: 50vw;
        }

        .site--dark & {
            border-left: 1px solid $grey;
        }

        &__input {
            width: calc(100% - #{map-get($navHeight, tablet) - 1px});
        }        

    }

}

@media screen and (min-width: 1025px) {

    .search {

        height: map-get($navHeight, desktop) - 1px;
        width: map-get($navHeight, desktop) - 1px;

        &__icon {
            height: map-get($navHeight, desktop) - 1px;
            width: map-get($navHeight, desktop) - 1px;
            min-width: map-get($navHeight, desktop) - 1px;            
        }

        &--open {
            width: 30vw;
        }

        &__input {
            width: calc(100% - #{map-get($navHeight, desktop) - 1px});
        }        

    }

}

@include media(map-get($breakpoints, mobile)) { 

    .search { 

        height: map-get($navHeight, mobile);
        width: map-get($navHeight, mobile);

        &--open {
            width: 100vw;
        }        

        &__icon,
        &__input {            
            font-size: $bodySize - 4;
            line-height: $bodySizeLH - 4;
        }
        &__icon {
            height: map-get($navHeight, mobile);
            width: auto;
            min-width: auto;
            padding: 0 map-get($padding, mobile);        
            svg {
                width: 12px;
            }
        }
        &__input {
            width: calc(100% - #{map-get($navHeight, mobile)});
        }

    }

}