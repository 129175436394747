.home-container {
  @include display(flex);
  @include flex-direction(row);
  @include flex-wrap(wrap)
  @include justify-content(flex-start);
  @include align-items(flex-start);
}

.post-item {
  color: $black;

  margin-bottom: 60px;

  @include transition-property(transform, opacity, visibility);
  @include transition-duration(0.5s);

  &--hidden {
    opacity: 0;
    visibility: hidden;
    @include transform(translateY(30px));
  }

  &__info {
    display: block;

    margin-top: 10px;

    color: $grey;
  }

  &__title {
    font-size: $midSize;
    line-height: $midSizeLH;
  }

  //desktop
  @media screen and (min-width: 1025px) {
    @include jtspan-columns(3 of 12);

    @include omega(4n);
  }  

  //tablet portrait
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @include jtspan-columns(4 of 12);

    @include omega(3n);    
  }

  //mobile
  @media screen and (max-width: 767px) {
    @include jtspan-columns(6 of 12);

    @include omega(2n);
  }  
}

.home-nav {

  &__items {
    @include jtspan-columns(6);
    @include shift(6);

    @include display(flex);
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
  }
}

.load-more {
  
}

.category-controller {
  padding-top: map-get($navHeight, desktop) + 20px;
}

.home-controller.actor,
.category-controller.actor {
  opacity: 1;

  &.trans--rule {
    @include transition(opacity, .5s);
  }

  &.trans--out,
  &.trans--in {
    opacity: 0;
  }
}

@include media(map-get($breakpoints, mobile)) {

  .post-item {
    @include jtspan-columns(2);
    margin-bottom: 13px;
    &__title {
      font-size: $midSize - 8;
      line-height: $midSizeLH - 8;
    }    
  }

}