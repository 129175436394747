$text: lighten($grey, 33);

.page-container {

  padding-top: #{ map-get($navHeight, desktop) + 60px };
  padding-bottom: 60px;

  color: $white;

  &__content {
    margin: 0;

    color: $text;

    text-align: center;

    a {
      color: lighten($grey, 50);
      &:hover {
        color: $white;
        opacity: 1;
      }
    }

    h5 {
      color: $white;
    }

    &__large {
      @include headlineFont();

      padding-bottom: 50px;

      color: $white;

      font-size:$headlineSize;
      line-height:$headlineSizeLH;      
    }
  }

  &__info-fields {
    padding: 40px 0;
    margin: 0;
  }

  &__region-select {
    padding-bottom: 30px;
    padding-top: 60px;
  }
}

.box {
  padding: 0 20px;
  margin-bottom: 30px;

  color: $text;

  text-align: center;

  a {
    color: lighten($grey, 50);
  }    

  h5 {
    color: $white;
  }
}

.page-container__content__large.actor {
  opacity: 1;

  &.trans--rule {
    @include transition-property(opacity, transform);
    @include transition-delay(.5s);
    @include transition-duration(.5s);
  }

  &.trans--out,
  &.trans--in {
    @include transform(translateY(-43px));
    opacity: 0;
  }

  &.trans--out {
    @include transition-delay(0s);
  }
}

.page-container__info-fields.actor,
.page-container__content__body.actor,
.page-container__region-select.actor,
.page-container__stockists.actor {
  opacity: 1;

  &.trans--rule {
    @include transition-property(opacity);
    @include transition-duration(.5s);
  }

  &.trans--out,
  &.trans--in {
    opacity: 0;
  }
}

@media screen and (min-width: 781px) {

  .page-container {
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    
    padding-left: map-get($padding, desktop);
    padding-right: map-get($padding, desktop);

    &__content {
      @include jtspan-columns(6 of 12);

      p {
        margin-bottom: $bodySize * $bodySizeLH;
      }      
    }

    &__stockists {
      @include display(flex);
      @include flex-direction(row);
      @include flex-wrap(wrap);
      @include justify-content(flex-start);
      @include jtspan-columns(12 of 12);    
    }

    &__info-fields {
      @include jtspan-columns(5 of 12);

      @include display(flex);
      @include flex-direction(row);
      @include flex-wrap(wrap);
      @include justify-content(flex-start);      
    }    
  }

  .box {
    width: 50%;
  }

  .page-container__stockists.actor {
    border-left: 1px solid $white;     
  }

}

@include media(map-get($breakpoints, tablet-portrait)) {

  .page-container {
    padding-top: #{ map-get($navHeight, tablet) + 60px };

    &__content {
      padding-left: map-get($padding, tablet);
      padding-right: map-get($padding, tablet);
    }

  }  

}

@include media(map-get($breakpoints, mobile)) {

  .page-container {
    padding-top: #{ map-get($navHeight, mobile) };
    padding-bottom: 60px;
    
    &__nav {
      width: 100%;
      padding-bottom: 0;
      margin-bottom: 38px;
      border-bottom: 1px solid #404040;

      .nav-items__item {
        margin: 0 8px;
      }

      .nav-items__item__link--active {
        border-bottom: 2px solid $white;
      }

    }

    &__content {
      padding-left: map-get($padding, mobile);
      padding-right: map-get($padding, mobile);

      &__large {
        padding-bottom: 50px;

        font-size:$headlineSize - 18;
        line-height:$headlineSizeLH  - 19;      
      }

      p {
        margin-bottom: ($bodySize - 3) * $bodySizeLH;
      }      
    }

    &__info-fields {
      padding: 40px 0 0 0;

    }

  }

}


