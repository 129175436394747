.btn {
  @include headlineFont();

  display: inline-block;

  padding: 8px 15px 8px 15px;

  font-size: $bodySize;
  line-height: 1;

  color: $white;

  cursor: pointer;

  background-color: $black;
  border: 0;

  @include transition(opacity .2s);

  &:hover {
    opacity: 0.6;
  }

  &--white {
    color: $black;
    
    background-color: white;
  }

  &--outline {
    color: $black;

    background-color: transparent;

    border: 1px solid $black;
  }

  &--big {
    padding: 10px 40px;

    font-size: $midSize;
    line-height: 1;
  }
  svg {
    display: inline-block;
    width: 19px;
    height: 11px;
    fill: $white;
    margin-left: 6px;
    vertical-align: middle;
  }

}

@include media(map-get($breakpoints, tablet-portrait)) {

  #stockistOpen {
    width: 100%;
  }

}

@include media(map-get($breakpoints, mobile)) {

  #stockistOpen {
    font-size: $midSize - 8;

    svg {
      width: 13px;
      height: 18px;
      margin-left: 4px;
    }      
  }

}