$logo-wid: 300px;

.nav-bar {
  position: fixed;

  z-index: 1000;

  width: 100%;

  color: $grey;

  @include transition-property(transform, color)
  @include transition-duration(.2s);

  &--top {
    top: 0;

    .navigation {
      border-bottom: 1px solid $bordergrey;   
    }

    .bite--on & {
      @include transform(translateY(-100%));
    }

    .bite--na & {
      @include transform(translateY(0%));
    }
  }

  &--bottom {
    bottom: 0;

    // display: none; // Just simulating it not being there

    .navigation {
      border-top: 1px solid $bordergrey;
    }

    .bite--on &,
    .bite--na & {
      @include transform(translateY(100%));
    }

  }

  .site--dark & {
    color: $text;

    .navigation {
      border-color: $grey;
    }
 
  }
}

.navigation {
  @include display(flex);
  @include flex-direction(row);
  @include justify-content(space-between);
  @include align-items(flex-end);
  @include transition(background-color .2s);

  background-color: $white;

  .site--dark & {
    color: $text;

    background-color: $black;
    a {
      @include transition(opacity .2s, color .2s); 
      &:hover {
        color: $white;
        opacity: 1;        
      }
    }
  }

  .nav-bar--bottom & {
    @include align-items(flex-start);
  }

  &__item {
    &--wide {
      width: 44%;
      width: calc((100% - 300px) / 2);
    }

    &--small {
      @include align-self(flex-start);

      min-width: $logo-wid;
      width: $logo-wid;

      line-height: 0;

      .nav-bar--bottom & {
        @include align-self(flex-end);
      }
    }

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
      height: 45px;
    }

    &--right {
      text-align: right;
      .social {
        padding-right: 30px;
      }
    }

    &__items {
      @include display(flex);
      @include flex-direction(row);
      @include justify-content(flex-start);
      @include align-items(center);
      
      padding-left: map-get($padding, desktop);
    }

    &__tagline a {
      .site--dark & {
        color: $text;        
      }   
    }

    &__logo {
      display: block;

      width: $logo-wid;
      height: 61px;

      font-size: 0;
      line-height: 0;
      color: transparent;

      margin-top: -18px;

      .site--dark & {
        svg {
          fill: $white;
        }
      }

      .nav-bar--bottom & {
        margin-top: 21px;
      }
    }
  }
}

.navigation__item {

  &--searchbox {
    @include display(flex);
    @include flex-direction(row);
    @include justify-content(flex-end);
    @include align-items(center);
  }

  &__search {
    margin-left: $bodySize;

    border-left: 1px solid $bordergrey;

    .site--dark & {
      border-left-color: $grey;
    }
  }
}

@media screen and (min-width: 481px) {

  .navigation__item {

    &__search {
      height: map-get($navHeight, tablet);
      min-width: map-get($navHeight, tablet) - 1px;
      width: map-get($navHeight, tablet) - 1px;
    }
  }  

}

@media screen and (min-width: 1025px) {

  .navigation__item {

    &__search {
      height: map-get($navHeight, desktop) - 1px;
      min-width: map-get($navHeight, desktop) - 1px;
      width: map-get($navHeight, desktop) - 1px;
    }
  }

}



@include media(map-get($breakpoints, tablet-landscape)) {  

  .navigation {

    &__item {

      &__items {
        padding-left: 0;
        ul {
          display: none;      
        }
      }

    }

  }
  
}

@include media(map-get($breakpoints, mobile)) {  

  .navigation {

    &__item {

      &--small,
      &__logo {
        width: auto;
        height: 33px;        
      }

      &__logo {
        margin-top: -6px;
      }
    
      &--small {
        min-width: 162px;  
      }    

      &__search {
        height: map-get($navHeight, mobile);
        min-width: map-get($navHeight, mobile);
        width: map-get($navHeight, mobile);

        border-left: 0;

      }

    }

  }

}

