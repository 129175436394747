.sidebar-fixed {
  position: fixed;
  width: 100%;
  top: 0;
}

.sidebar {
  @include jtspan-columns(3 of 16);

  position: absolute;

  top: 0;
  right: 0;

  height: 100vh;

  overflow: auto;

  // padding-top: map-get($navHeight, desktop);
  // padding-bottom: map-get($navHeight, desktop);
  
  margin-right: 0;

  border-left: 1px solid $bordergrey;
}

.post-sidebar {
  &__item {
    padding-right: map-get($padding, desktop);

    border-bottom: 1px solid $bordergrey;
  }

  &__link {
    position: relative;

    display: block;

    padding: 10px;

    &__percentage {
      position: absolute;

      left: 0;
      top: 0;

      width: 5px;
      height: 100%;

      background-color: $lightgrey;
    }

    &__percentage__bar {
      position: absolute;

      left:0;
      top: 0;

      width: 100%;
      height: 100%;

      @include transform(scale(1, 0));
      @include transform-origin(top center);

      background-color: $grey;

      &.complete {
        @include transform(scale(1, 1));
      }

      &.zero {
        @include transform(scale(1, 0));
      }
    }
    
    &__info {
      font-size: 15px;
      line-height: 17px;
      display: block;

      text-overflow: ellipsis;
      white-space: nowrap;      
    }
    
    &__title {
      width: 100%;

      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}