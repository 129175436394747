.lazy-image {
	position: relative;
	background-color: #eee;
	line-height: 0;

	&__image {
		opacity: 0;

		@include transition(opacity .2s);
	}

	&--loaded {
		.lazy-image__image {
			opacity: 1;
		}
	}
}