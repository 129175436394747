.single-post {

  padding: 0; // Remove this if it should use the site wrapper

  min-height: 100vh;

  &__content {
    padding-top: map-get($navHeight, desktop) + 20px;
    padding-left: map-get($padding, desktop);

    min-height: 1px;
  }
}

.article__left.actor {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;

  &.trans--rule {
    @include transition-property(opacity, transform)
    @include transition-duration(.5s);
  }

  &.trans--out {
    opacity: 0;
    transform: translateX(-100px);
  }

  &.trans--in {
    opacity: 0;
    transform: translateX(100px);
  }
}

.article__right.actor {
  opacity: 1;

  &.trans--rule {
    @include transition-property(opacity)
    @include transition-duration(.5s);
  }

  &.trans--out,
  &.trans--in {
    opacity: 0;
  }

  @media screen and ( min-width: 800px ){
    padding-bottom: 120px;
  }

}

.article__right__body {
  a {
    color: $grey;
  }
  p {
    margin-bottom: 1.2em;
  }
  img{
    display: block;
  }
}

@include media(map-get($breakpoints, tablet-landscape)) {

  .single-post {

    &__content {
      padding-top: map-get($navHeight, tablet) + 20px;
      padding-left: map-get($padding, tablet);
      padding-right: map-get($padding, tablet);    
    }

  }

}

@include media(map-get($breakpoints, mobile)) {

  .single-post {

    &__content {
      padding-top: map-get($navHeight, mobile) + 20px;
      padding-left: 0;
      padding-right: 0;
    }

  }

}  