$text: lighten($grey, 20);

.locations {
	//@include display(flex);
	//@include align-items(center);
	@include justify-content(center);

    text-align: center;

    position: fixed;

    z-index: 10000;

    width: 100%;
    height: 100%;
    height: 100vh;
    padding: 60px 0 118px 0;

    opacity: 0;
    visibility: hidden;

    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    color: $white;

    background-color: rgba(0,0,0,0.9);

    @include transition-property(opacity, visibility);
    @include transition-duration(0.25s);

    a {
        color: $white;
    }

    &--show {
        opacity: 1;
        visibility: visible;
    }

    &__content {

    }
    .sub-locations {
        a {
            color: $text;
        }
    }
}