@import url("//hello.myfonts.net/count/312568");

@import "bourbon";
@import "neat";
@import "libs/reset";
@import "libs/ie";

$max-width:100%;

$breakpoints : (
	desktop: 			new-breakpoint(min-width 1025px 16),
	tablet-landscape: 	new-breakpoint(max-width 1024px 6),
	tablet-portrait: 	new-breakpoint(max-width 768px 6),
	mobile:				new-breakpoint(max-width 480px 4)
);

$padding : (
	desktop: 30px,
	tablet: 20px,
	mobile: 20px
);

$navHeight: (
	desktop:  	58px,
	tablet: 	58px,
	mobile:		40px
);



/*
=================================================

									MIXINS

=================================================
*/

@mixin jtContainer
{
	@include outer-container;
	position:relative;

	width:$max-width;
	// max-width:1800px;

	// padding-left: map-get($padding, desktop);
	// padding-right: map-get($padding, desktop);
}

@mixin jtspan-columns($span: $columns of $container-columns) {
  $columns: nth($span, 1);
  $container-columns: container-span($span);

  $parent-columns: get-parent-columns($container-columns) !global;

  $direction: get-direction($layout-direction, $default-layout-direction);
  $opposite-direction: get-opposite-direction($direction);

  margin-#{$direction}: flex-gutter($container-columns);
  min-width: flex-grid($columns, $container-columns);
  width: flex-grid($columns, $container-columns);

  &:last-child {
    margin-#{$direction}: 0;
  }
}


/*
=================================================

									TYPOGRAPHY

=================================================
*/	

	@include font-face("BemboStd", "font/312568_0_0");
	@include font-face("BemboStd-Italic", "font/312568_1_0");
	@include font-face("BemboStd-Bold", "font/312568_3_0");

	$featuredSize : 34px;
		$featuredSizeLH : 34px;

	$headlineSize : 42px;
		$headlineSizeLH : 43px;

	$subheadlineSize : 32px;
		$subheadlineSizeLH : 34px;

	$midSize : 24px;
		$midSizeLH : 26px;

	$smallSize : 11px;
		$smallSizeLH : 13px;

	$bodySize : 17px;
		$bodySizeLH : 1.2;

	$navSize : 18px;
		$navSizeLH : 1;

	$metaSize : 13px;
		$metaSizeLH : 15px;

	@mixin headlineFont($style: 'regular') {
		@if $style == 'regular' {
			font-family:'BemboStd', sans-serif;
		}
		@else if $style == 'italic' {
			font-family:'BemboStd-Italic', sans-serif;
		}
		@else if $style == 'bold' {
			font-family:'BemboStd-Bold', sans-serif;
		}

		font-weight: normal;
		font-style: normal;

		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@mixin bodyFont($style: 'regular') {
		@if $style == 'regular' {
			font-family:'BemboStd', sans-serif;		
		}
		@else if $style == 'italic' {
			font-family:'BemboStd-Italic', sans-serif;
		}
		@else if $style == 'bold' {
			font-family:'BemboStd-Bold', sans-serif;
		}

		font-weight: normal;
		font-style: normal;

		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing: grayscale;
	}



/*
=================================================

									COLOURS

=================================================
*/

	$black : #000000;
	$white : #FFFFFF;
	$grey  : lighten($black,25);
	$lightgrey  : darken($white,5);
	$bordergrey : #bcbcbc;

	$error  : darken(#f00,5);

	.black {
		color:$black;
	}
		.black-bg {
			color:$black;
		}

	.white {
		color:$white;
	}
		.white-bg {
			color:$white;
		}

	.grey {
		color:$grey;
	}
		.grey-bg {
			color:$grey;
		}

	.lightgrey {
		color:$lightgrey;
	}
		.lightgrey-bg {
			color:$lightgrey;
		}

/*
=================================================

									GENERAL STYLES

=================================================
*/

	html, body {
		@include bodyFont();
		font-size:$bodySize;
		line-height:$bodySizeLH;
		height: 100%;
	}

	#ajax-replace {
		min-height: calc(100% - 300px);
	}

	html {
		@include transition(background-color, .2s);
		
		background-color: $white;

		&.site--dark {
			background-color: $black;
		}
	}

	.overflow {
		overflow:hidden;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight:normal;
	}
	h1 {
		@include headlineFont();
		font-size:$headlineSize;
		line-height:$headlineSizeLH;
	}
	h2 {
		@include headlineFont();
		font-size:$subheadlineSize;
		line-height:$subheadlineSizeLH;
	}
	h3 {
		@include headlineFont();
		font-size:$midSize;
		line-height:$midSizeLH;
	}

	h5 {
		@include bodyFont();
		margin-top: $bodySize * $bodySizeLH;
	}

		@include media(map-get($breakpoints, mobile)) {
			html, body {
				font-size:$bodySize - 3;
			}			
			h1 {
				font-size:$headlineSize;
				line-height:$headlineSizeLH;
			}
			h2 {
				font-size:$subheadlineSize;
				line-height:$subheadlineSizeLH;
			}
			h3 {
				font-size:$midSize - 8;
				line-height:$midSizeLH - 8;
			}

			h5 {
				margin-top: ($bodySize - 3) * $bodySizeLH;
			}			
		}

	.left-align {
		text-align:left;
	}
	.centre-align {
		text-align:center;
	}
	.right-align {
		text-align:right;
	}

	a {
		text-decoration:none;
		opacity:1;
		@include transition(opacity .2s, color .2s);
		color: $grey;

		&:hover {
			opacity:0.6;
		}
	}

	img, .wp-caption {
		max-width:100%;
		height:auto;
	}

	strong {
		@include bodyFont('bold');
	}

	em {
		@include bodyFont('italic');
	}

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }	

	@include media(map-get($breakpoints, tablet-landscape)) {
		main {
			@include transition-property(transform);
	  		@include transition-duration(.3s);
	    	@include transform-origin(100% 50%);  		

		    position: relative;
		    z-index: 2;		
		    background-color: $white;
		    overflow: hidden;
		    .site--dark & {
				background-color: $black;
		    }
		}		
	}	

	.clearfix {
		@include clearfix;
	}
	.container {
		@include jtContainer;

		&.padded {
			padding-left: map-get($padding, desktop);
			padding-right: map-get($padding, desktop);
		}

		&.padded-top {
			padding-top: map-get($padding, desktop);
		}

		&.padded-bottom {
			padding-bottom: map-get($padding, desktop);
		}		

		@include media(map-get($breakpoints, tablet-landscape)) {
			&.padded-top {
				padding-top: map-get($padding, tablet);
			}

			&.padded-bottom {
				padding-bottom: map-get($padding, tablet);
			}

			&.padded {
				padding-left: map-get($padding, tablet);
				padding-right: map-get($padding, tablet);
			}
		}

		@include media(map-get($breakpoints, mobile)) {
			&.padded-top {
				padding-top: map-get($padding, mobile);
			}

			&.padded-bottom {
				padding-bottom: map-get($padding, mobile);
			}

			&.padded {
				padding-left: map-get($padding, mobile);
				padding-right: map-get($padding, mobile);
			}
		}

	}

	.overlay {

		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;

		&--main {
			display: none;
			z-index: 9999;
		}

	}

	// Hide on mobile
	@include media(map-get($breakpoints, mobile)) {
		.hidebelow480 {
			display: none;
		}	
	}

	// Hide on tablet
	@include media(map-get($breakpoints, tablet-landscape)) {
		.hidebelow1024 {
			display: none;
		}
	}	

	// Hide on desktop
	@include media(map-get($breakpoints, desktop)) {
		.hideabove1024 {
			display: none;
		}
	}



/*
======================================

	PARTIALS

======================================
*/


@import "elements/button";
@import "elements/signup";
@import "elements/post-sidebar";
@import "elements/single-post";
@import "elements/share";
@import "elements/advert";
@import "elements/social";
@import "elements/nav";
@import "elements/splash";
@import "elements/locations";
@import "elements/stockist";
@import "elements/search-box";
@import "elements/lazy-image";
@import "elements/error";


@import "partials/header";
@import "partials/home";
@import "partials/post";
@import "partials/page";
@import "partials/footer";


