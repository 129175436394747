$text: lighten($grey, 33);

.signup {
  width: 250px;

  margin: 10px auto 90px auto;

  text-align: center;

  label {
    display: inline-block;
    color: $text;
    margin-bottom: 4px;
  }

  &--alt {
    margin: 0 auto 30px auto;
  }

  &--disabled {
    opacity: 0.8;
  }

  &__message {
    color: $white;

    .signup--alt & {
      color: $black;
    }

    .signup--msg-error & {
      color: $error;
    }
  }

  &__title {
    margin-bottom: 20px;

    color: $white;

    // font-size: $midSize;
    // line-height: $midSizeLH;
    font-size: 28px;
    line-height: 1;

    .signup--alt & {
      color: $black;
    }
  }

  &__description {
    margin-bottom: 10px;

    color: $text;
  }

  &__input {

    @include bodyFont();

    font-size: $bodySize;
    line-height: 1;
    margin-bottom: 10px;

    width: 100%;

    padding: 3px 8px 4px 8px;

    background-color: transparent;
    border: 1px solid $grey;

    color: $white;

  }

  &__button {
    background-color: $white;
    margin-top: 10px;
    color: $black;
  }

  &--alt {

    margin: 40px auto;

    .signup__button {
      background-color: $black;
      color: $white;      
    }

    .signup__input {
      border-color: $bordergrey;

      color: $black;
    }

  }

}