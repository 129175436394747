$text: lighten($grey, 20);

.stockist {
  padding-bottom: 20px;

  text-align: center;

  p {
    color: $text;
  }

  &--hide {
    display: none;
  }

  @media screen and (min-width: 781px) {

    border-right: 1px solid $white; 

    width: 25%;
    min-width: 25%;

    &:not(.stockist--hide) {
      @include omega(4n);  
    }
    
  }
}
