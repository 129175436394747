.error {
	@include display(flex);
	@include align-items(center);
	@include justify-content(center);

	@extend h3;

	width: 100%;
	height: 100%;

	position: fixed;

	z-index: 1;

	text-align: center;

	color: $black;

	svg {
		display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
		@include transition(opacity .2s, fill .2s);
	} 
}