.share-box {
  width: 250px;

  margin: 30px auto;
  margin-bottom: 60px;

  text-align: center;

  &__title {
    margin-bottom: 10px;

    color: $black;
  }

  &__items {
    @include display(flex);
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(center);

    &__item {
      margin:0 4px;
    }
  }
}

.share-link {
  display: block;

  width: 32px;
  height: 32px;

  color: transparent;

  border: 1px solid $bordergrey;

  font-size: 0;

  &--facebook {
    
  }

  &--twitter {

  }

  &--pinterest {
    
  }
  padding: 7px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }  
}