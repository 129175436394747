$text: lighten($grey, 33);

.nav-items {

  &__item {
    display: inline-block;
    margin-right: 10px;
    
    &__link {
      display: block;

      padding: 20px 1px 16px 1px;

      color: inherit;

      border-bottom: 3px solid transparent;

      font-size: $navSize;
      line-height: $navSizeLH;

      &--active {
        border-bottom-color: $black;
        color: $black;
      }

      .site--dark & {
        border-bottom-color: transparent;

        &--active {
          border-bottom-color: $white;
          color: $white;
        }
      }
    }
  }

  &--no-highlight {

    text-align: center;

    padding-bottom: 30px;

    color: $text;    

    .nav-items__item__link,
    .nav-items__item__link--active {
      border: 0;
      &:hover {
        color: $white;
        opacity: 1;
      }
    }
  }
}

@include media(map-get($breakpoints, tablet-landscape)) {

  .nav-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    z-index: 1;

    .nav-items {

      &__item {
        display: block;
        margin-right: 0;

        &__link {
          border-bottom: 0;
          padding: 3px 0;

          &--active {
            opacity: 0.6;
            color: $white;
          }
        }

      }

    }

    &--top {

        padding-top: 84px;
        margin-bottom: -150px;

        min-height: 100%;
        height: auto!important;      

       .nav-items__item {

          &__link {
            font-size: 30px;

          }

      }

    }

    &--bottom,
    .push {
      height: 150px;
    }

    &--bottom {

        padding: 0 0 10px 0;

       .nav-items__item {

          &__link {
            font-size: 22px;
            color: $text;
          }

      }

    } 

    &__inner {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      padding: map-get($padding, mobile);
    }

    .closeicon {
      position: fixed;
      top: 0;
      right: 0;
      display: block;
      padding: map-get($padding, mobile);    
      svg {
        width: 20px;
        height: 20px;
        stroke: $text;
        stroke-width: 1px;
      }
    }

  }

  html.mobile-open {
    .nav-mobile {
      display: block;
    }
    .overlay--main {
      display: block;
    }  
    main {
      @include transform(scale(0.42));
      overflow: hidden;
      height: 100vh;
    }
  }

  .navicon-wrap {
    border-right: 1px solid $bordergrey;
    height: map-get($navHeight, tablet); 
    padding: 15px map-get($padding, tablet);
    .site--dark & {
      border-right-color: $grey;
    }    
  }

  .navicon {

    width: 18px;
    height: 100%;
    display: block;
    position: relative;

    .line {
      position: absolute;
      height: 2px;
      background-color: $grey;
      width: 100%;
      left: 0;

      &.one {
        top:25%;
      }
      &.two {
        top:50%;
        margin-top:-1px;
      }
      &.three {
        top:75%;
        margin-top:-2px;
      }
      .site--dark & {
        background-color: $text;
      }
      
    }

  }  

}

@include media(map-get($breakpoints, mobile)) {

  .navicon-wrap {
    border-right: none;
    height: map-get($navHeight, mobile); 
    padding: 6px map-get($padding, mobile);        
  }

}


