.advert {
  position: relative;
  overflow: hidden;

  background-color: $lightgrey;

  &--full {
    width: 100%;
    height: 0;

    padding-bottom: 25%;
  }

  &--inline {
    width: 100%;
    height: 266px;

    margin: 30px auto;
    overflow: hidden;
  }
}

.advert-content {
  position: absolute;
  

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center 0px;

  @include transition(opacity .5s);

  &:hover {
    opacity: 1;
  }

  &--video {
    video {
      display: none;
    }

    @include media(map-get($breakpoints, desktop)) {
      background-image: none;

      video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: block;
        transform: translateY(-50%);
      }      
    }
  }  

  &--hidden {
    opacity: 0 !important;
  }

  &__container {
    @include display(flex);
    @include align-items(center);
    z-index: 1;

    &--left {
      @include justify-content(flex-start);
      text-align: left;
    }

    &--center {
      @include justify-content(center);
      text-align: center;
    }

    &--right {
      @include justify-content(flex-end);
      text-align: right;
    }

    height: 100%;

    &__body {
      @include jtspan-columns(4);

      color: $white;

      h4 {
        font-size: $subheadlineSize;
        line-height: $subheadlineSizeLH;
      }

      p {
        padding-right: map-get($padding, desktop);
        padding-left: map-get($padding, desktop);
      }

      .advert-content--inline & {
        @include jtspan-columns(12);
        text-align: center;
      }
    }
  }

  &--full {
    background-attachment: fixed;
  }
}

@include media(map-get($breakpoints, tablet-landscape)) {

  .advert-content {
    &__container {
      &__body {
        p {
          padding-right: map-get($padding, tablet);
          padding-left: map-get($padding, tablet);
        }        
      }
    }
  }

}

@include media(map-get($breakpoints, tablet-portrait)) {
  .advert--full {
    padding-bottom: 34%;
  }
  .advert-content {
    &--full {
      background-attachment: scroll;      
    }
  }  
}

@include media(map-get($breakpoints, mobile)) {
  .advert--full {
    padding-bottom: 85%;
  }

  .advert--inline {
    margin: 0 auto;
  }

  .advert-content {
    &__container {
      &__body {
        p {
          padding-right: map-get($padding, mobile);
          padding-left: map-get($padding, mobile);
        }        
      }
    }
    &--full {
      background-attachment: scroll;      
    }
  }

}