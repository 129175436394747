.article {

  &__left {    

    &__title {

      display: block;
      margin-top: 80px;

      &__heading {
        font-size: $featuredSize;
        line-height: $featuredSizeLH;
      }

      &__meta {
        color: $grey;
        font-size: 20px;
        line-height: 22px;
      }

      svg {
          display: block;
          width: 25px;
          height: 25px;
          fill: $grey;
      }   
    }

    &__information {
      margin-top: 40px;
      margin-bottom: 128px;
      font-size: $smallSize;
      line-height: $smallSizeLH;
    }
  }

  &__right {

    &__body {

      overflow: hidden;

      img {
        margin-bottom: 20.4px * 2;        
      }

      .wp-caption {
        margin-top: $bodySize;
        margin-bottom: 20.4px * 2;
        font-size: $smallSize;
        line-height: $smallSizeLH;

        img {
          margin-bottom: 0;
        }

        &-text {
          margin-top: 10px;
          margin-bottom: 20.4px*2;
          text-align: center;
          font-size: $smallSize;
          line-height: $smallSizeLH;
        }
      }
    }
    &__signup {
      overflow: hidden;
    }
    &__share {
      .share-box {
        margin: 0 auto 50px auto;
        overflow: hidden;
      }
    }
  }

  &__featured {
    background-position: center top;
    background-repeat: no-repeat;
    transition: none;
    &:hover {
      opacity: 1;
    }
  }

  &__video {
    position: relative;
    margin-bottom: $bodySize;
    &::after {
      display: block;
      padding-top: 56.2%;
      content: "";
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

}

@media screen and (min-width: 481px) {

    .article {
      @include display(flex);
      @include flex-direction(row);

      &__left {
        @include display(flex);
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(flex-start);
        //@include jtspan-columns(8 of 16);
        width: 50%;
        min-width: 50%;
        padding-right: 40.8px;

        height: calc(100vh - #{map-get($navHeight, tablet)});

        &__title,
        &__information {
          @include transition(opacity 1s ease, transform .3s ease);
        }

        &--bottom {
          @include justify-content(flex-end);

          width: 50%;
          min-width: 50%;
          margin-right: 0;
          
          padding-left: map-get($padding, tablet);

          height: 100vh;      
        }

      }
      &__right {
        //@include jtspan-columns(8 of 16);
        width: 50%;
        min-width: 50%;
      } 

      &--present {

        .article__left {
          position: fixed;

          top: #{map-get($navHeight, tablet)};

          &__title,
          &__information {
            opacity: 1;
            @include transform(translateY(0));            
          }

        }

        .article__right {
          @include shift(8 of 16);
        }
      }

      &__featured {
        width: 50%;
        min-width: 50%;

        background-size: cover;
      }

      &--past {

        @include align-items(flex-end);

        .article__left__title,
        .article__left__information {

          opacity: 0;

          //fallback
          -o-transform: translateY(-o-calc(100vh / 2 * -1));    
          -ms-transform: translateY(c-ms-alc(100vh / 2 * -1));
          -moz-transform: translateY(-moz-calc(100vh / 2 * -1));
          -webkit-transform: translateY(-webkit-calc(100vh / 2 * -1));
          transform: translateY(calc(100vh / 2 * -1));          

        }

      }

      &--future {
        @include align-items(flex-start);

        .article__left__title,
        .article__left__information {

            opacity: 0;

            //fallback
            -o-transform: translateY(-o-calc(100vh / 2));    
            -ms-transform: translateY(c-ms-alc(100vh / 2));
            -moz-transform: translateY(-moz-calc(100vh / 2));
            -webkit-transform: translateY(-webkit-calc(100vh / 2));
            transform: translateY(calc(100vh / 2));

        }

      }

      &__spacer {
        @include jtspan-columns(4 of 16);
      }     

    }

}

@media screen and (min-width: 1025px) {

  .article {

    &__left {
      @include jtspan-columns(4.75 of 16);

      height: calc(100vh - #{map-get($navHeight, desktop)});

      &--bottom {
        width: 50%;
        min-width: 50%;
        margin-right: 0;

        padding-left: map-get($padding, desktop);
      }
    }
    &__right {
      @include jtspan-columns(6.5 of 16);
    } 

    &--present {

      .article__left {
        top: #{map-get($navHeight, desktop) + 20px};
      }

      .article__right {
        @include shift(4.75 of 16);
      }        

    }

    &__spacer {
        @include jtspan-columns(4.75 of 16);
    }  

  }

}

@include media(map-get($breakpoints, mobile)) {

    .article {
      @include display(flex);
      @include flex-flow(column);

      top: #{map-get($navHeight, mobile)};      

      &--not-featured {
        .article__left__title {
          text-align: center;
        }
      }

      &__left {

        padding-left: map-get($padding, mobile);
        padding-right: map-get($padding, mobile);

        &--bottom {
          padding-left: map-get($padding, mobile);
          padding-right: map-get($padding, mobile);

          

          .article__left__title {
            margin-top: 10px;
          }
        }

        &__title {

          margin-top: 36px;

          &__heading {
            font-size: $featuredSize - 6;
            line-height: $featuredSizeLH - 4;
          }

          &__meta {
            font-size: 17px;
            line-height: 21px;
          }          
        }

        &__information {
          margin-bottom: 28px;
        }

      }

      &__right {

        &__body {

          padding-left: map-get($padding, mobile);
          padding-right: map-get($padding, mobile);

          .wp-caption {
            margin-top: $bodySize - 3;
            margin-bottom: 24px;
            &-text {
              margin-top: $bodySize - 3;
              margin-bottom: 24px;
            }            
          }

        }

        &__share {
          margin-top: 12px;
        }

        &__signup {
          border-top: 1px solid $bordergrey;
          padding: 72px 0 70px 0;
        }

        &__advert {
          overflow: hidden;
        }

      }      

      &__featured {

        margin-top: calc(#{map-get($padding, mobile)} + #{map-get($navHeight, mobile)});
        background-size: contain;

        @include order(-1);

        padding-top: 85%;
      }      
  
    }

    .article--present {
      .article__left {
        top: #{map-get($navHeight, mobile)};
      }      
    }


}