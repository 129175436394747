.splash {
	@include display(flex);
	@include align-items(center);
	@include justify-content(center);

	@extend h3;

	position: fixed;

	z-index: 10000;

	width: 100%;
	height: 100%;

	text-align: center;

	color: $black;

	background-color: white;
	background-size: cover;

	&:hover {
		opacity: 1;
	}
}